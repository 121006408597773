<template>
  <div>
    <div class="chat-top flex al-c">
      <img
        v-if="userProfile.avatar"
        :src="userProfile.avatar"
        alt=""
        class="top-avatar"
      />
      <div class="top-name">
        {{ userProfile.nick || "" }}
      </div>
    </div>
    <div class="chat-box scrollView" ref="div">
      <div
        @contextmenu.prevent="Scatterback = index"
        class="box"
        v-for="(item, index) in messageList"
        :key="index"
        style="width: 100%"
      >
        <div>
          <div
            class="time-text"
            v-if="
              showMessageHistoryTime &&
              item.isShowHistoryTime &&
              item.flow == 'out'
            "
          >
            {{ messageHistoryTime }}
          </div>
          <div
            @mouseleave="Scatterback = null"
            v-if="index == Scatterback"
            class="Rightpiece-box"
          >
            <span v-if="item.flow == 'out'" @click="surveytime(item)"
              >撒回</span
            >
            <span style="margin: 0 5px" v-if="item.flow == 'out'">|</span>
            <span @click="deleteMessage(item)">删除</span>
          </div>
          <div v-if="item.isRevoked" class="f-z z14-66" style="width: 100%">
            <div v-if="item.flow == 'out'">你撒回了一条消息</div>
            <div v-else>对方撒回了一条消息</div>
          </div>
          <div v-else class="flex" :class="item.flow === 'out' ? 'jc-end' : ''">
            <img
              class="img"
              v-if="item.flow == 'in'"
              :src="item.avatar"
              alt=""
            />
            <div class="z14-66" v-if="item.flow === 'out'">
              <div v-if="item.isPeerRead">已读</div>
              <div v-else>未读</div>
            </div>
            <!-- 文字 -->
            <div
              v-if="item.type === 'TIMTextElem'"
              class="TIM flex"
              :class="item.flow == 'out' ? 'TIM2' : ''"
            >
              <div v-for="(item, index) in Text(item)" :key="index">
                <span v-if="item.name === 'span'">{{ item.text }}</span>
                <img
                  style="width: 30px; height: 30px"
                  v-if="item.name === 'img'"
                  class="emoji-icon"
                  :src="item.src"
                />
              </div>
            </div>
            <!-- 图片 -->
            <div
              v-else-if="item.type === 'TIMImageElem'"
              class="TIM"
              :class="item.flow == 'out' ? 'TIM2' : ''"
            >
              <img
                style="max-width: 200px; max-height: 200px"
                :src="item.payload.imageInfoArray[0].url"
                alt=""
              />
            </div>
            <!--  -->
            <div
              v-else-if="item.type === 'TIMSoundElem'"
              class="TIM"
              :class="item.flow == 'out' ? 'TIM2' : ''"
            >
              <audio controls ref="audio" class="aud">
                <source :src="item.payload.url" />
              </audio>
            </div>
            <!-- 自定义 -->
            <div
              v-else-if="item.type === 'TIMCustomElem'"
              class="TIM"
              style="background-color: #ffffff; color: #333; text-align: center"
              :class="item.flow == 'out' ? 'TIM2' : ''"
            >
              <div class="order-box" v-if="item.payload.data == 'order'">
                <p class="bold">订单</p>
                <div>
                  <p>课程名称:{{ change(item.payload.extension).title }}</p>
                  <p>
                    课程类型:{{ change(item.payload.extension).categories }}
                  </p>
                  <p>{{ change(item.payload.extension).amount }}元</p>
                </div>
              </div>
              <div class="order-box" v-if="item.payload.data == 'need'">
                <p class="bold">需求</p>
                <div>
                  <p>课程名称:{{ change(item.payload.extension).title }}</p>
                  <p>
                    课程类型:{{
                      change(item.payload.extension).service_type == 3
                        ? "到店"
                        : "上门"
                    }}
                  </p>
                </div>
              </div>
              <div
                v-if="item.flow == 'out'"
                style="color: #fc781d"
                @click="revise(item.payload.extension)"
                class="shou"
              >
                查看详情
              </div>
              <div
                v-else
                style="color: #fc781d"
                class="shou"
                @click="RefDemand(item.payload.extension)"
              >
                查看详情
              </div>
            </div>
            <div v-else>
              {{ item.type }}
            </div>
            <img
              class="img"
              v-if="item.flow == 'out'"
              :src="item.avatar"
              alt=""
            />
          </div>
          <div
            class="time-text"
            style="margin-left: 50px"
            v-if="
              showMessageHistoryTime &&
              item.isShowHistoryTime &&
              item.flow == 'in'
            "
          >
            {{ messageHistoryTime }}
          </div>
        </div>
      </div>
      <div v-if="isCompleted" class="tips">没有更多了</div>
    </div>
    <div class="Tool">
      <Tool
        @sendMessage="sendMessage"
        :user="conversation"
        ref="SendOut"
      ></Tool>
    </div>
    <User-demand ref="OpanDemand"></User-demand>
  </div>
</template>

<script>
import { parseText } from "../base/message-facade";
import { dayjs } from "../base/common";
import Tool from "./tool";
import UserDemand from "./userdemand";
export default {
  name: "Chatpage",
  components: {
    Tool,
    UserDemand,
  },
  data() {
    return {
      messageList: [],
      Name: "",
      nextReqMessageID: [],
      isCompleted: true,
      nextReqMessageID: "",
      showMessageHistoryTime: "",
      firstTime: "",
      messageHistoryTime: "",
      messageTime: "",
      showMessageTime: "",
      Scatterback: null,
      scrollbar: 0,
      coach_List: [],
      userProfile: {},
    };
  },
  props: {
    conversation: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  watch: {
    $route(e) {
      if (e.name == "timHome") {
        this.$nextTick(() => {
          this.$refs.div.scrollTop = this.$refs.div.scrollHeight;
        });
      }
    },
    conversation: {
      handler: function (nv) {
        if (nv.conversationID) {
          this.userProfile = nv.userProfile;
          this.$tim.setMessageRead({
            conversationID: nv.conversationID,
          });
          // 获取聊天列表
          this.$tim
            .getMessageList({
              conversationID: nv.conversationID,
              count: 15,
            })
            .then((res) => {
              this.isCompleted = res.data.isCompleted;
              this.nextReqMessageID = res.data.nextReqMessageID;

              const { messageList } = res.data; // 消息列表。
              this.messageList = messageList;
               this.toBottom()
            });
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.$tim.on(
      this.$tims.EVENT.MESSAGE_READ_BY_PEER,
      this.$onMessageReadByPeer,
      this
    );
    this.$tim.on(
      this.$tims.EVENT.MESSAGE_REVOKED,
      this.$onMessageRevoked,
      this
    );
    this.$tim.on(this.$tims.EVENT.MESSAGE_RECEIVED, this.$onMessageReceived);
    // this.$tim.on(this.$tims.EVENT.MESSAGE_RECEIVED, this.$onMessageReceived, this);
    this.$refs.div.addEventListener("scroll", this.onscoll);
  },
  beforeDestroy() {
    this.$tim.on(this.$tims.EVENT.MESSAGE_RECEIVED, this.$onMessageReceived);
    this.$tim.off(
      this.$tims.EVENT.MESSAGE_READ_BY_PEER,
      this.$onMessageReadByPeer,
      this
    );
    this.$tim.off(
      this.$tims.EVENT.MESSAGE_REVOKED,
      this.$onMessageRevoked,
      this
    );
  },
  methods: {
    toBottom() {
      this.$nextTick(() => {
        var container = this.$el.querySelector(".scrollView");
        container.scrollTop = container.scrollHeight;
      });
    },
    $onMessageReceived(value) {
      this.$tim
        .setMessageRead({
          conversationID: this.conversation.conversationID,
        })
        .then(() => {});
      // 若需修改消息，需将内存的消息复制一份，不能直接更改消息，防止修复内存消息，导致其他消息监听处发生消息错误
      console.log("$onMessageReceived");
      const event = value;
      const list = [];
      event.data.forEach((item) => {
        if (item.conversationID === this.conversation.conversationID) {
          list.push(Object.assign(item));
        }
      });
      this.messageList = this.messageList.concat(list);
      this.toBottom()
    },

    RefDemand(e) {
      var id = JSON.parse(e).id;
      this.$refs.OpanDemand.open(id);
    },
    revise(e) {
      var id = JSON.parse(e).id;
      this.$store.commit("SET_need_ids", id);
      this.$router.push({
        name: "wyneed",
      });
    },

    // 删除消息
    deleteMessage(item) {
      this.$tim
        .deleteMessage([item])
        .then((imResponse) => {
          this.updateMessageByID(imResponse.data.messageList[0].ID);
        })
        .catch(() => {
          this.$Ts("删除失败");
        });
    },
    // 向对方通知消息撤回事件
    $onMessageRevoked(event) {
      // this.updateMessageByID(event.data[0].ID);
    },
    // 更新信息
    updateMessageByID(deleteMessageID) {
      const deleteMessageArr = this.messageList.filter(
        (item) => item.ID != deleteMessageID
      );
      this.messageList = deleteMessageArr;
    },
    surveytime(message) {
      this.$tim
        .revokeMessage(message)
        .then((imResponse) => {
          this.Scatterback = null;
        })
        .catch((imError) => {
          this.$Ts("消息撤回,不能高于两分钟");
        });
    },
    // 渲染历史消息时间
    showHistoryMessageTime(messageList) {
      const cut = 5 * 60 * 1000;
      for (let index = 0; index < messageList.length; index++) {
        const nowadayTime =
          Math.floor(messageList[index].time / 10) * 10 * 1000;
        const firstTime = messageList[0].time * 1000;
        console.log(firstTime);
        if (nowadayTime - firstTime > cut) {
          const indexbutton = messageList
            .map((item) => item)
            .indexOf(messageList[index]); // 获取第一个时间大于10分钟的消息所在位置的下标
          const firstTime = nowadayTime; // 找到第一个数组时间戳大于30分钟的将其值设为初始值
          const showHistoryTime =
            Math.floor(messageList[indexbutton].time / 10) * 10 * 1000;
          Object.assign(messageList[indexbutton], {
            isShowHistoryTime: true,
          });
          this.firstTime = nowadayTime;
          this.messageHistoryTime = dayjs(showHistoryTime);
          this.showMessageHistoryTime = true;
          return firstTime;
        }
      }
    },
    onscoll() {
      if (this.$refs.div.scrollTop == 0 && !this.isCompleted) {
        let scrollTop = this.$refs.div.scrollHeight;
        this.$tim
          .getMessageList({
            conversationID: this.conversation.conversationID,
            nextReqMessageID: this.nextReqMessageID,
          })
          .then((imResponse) => {
            this.isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。isCompleted 为 true 时，nextReqMessageID 为 ""。
            this.nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
            this.messageList = [
              ...imResponse.data.messageList,
              ...this.messageList,
            ];
            this.$nextTick(() => {
              this.showHistoryMessageTime(this.messageList);
              this.$refs.div.scrollTop =
                this.$refs.div.scrollHeight - scrollTop;
            });
          });
      }
    },
    sendMessage(E) {
      this.messageList.push(E);
      this.$nextTick(() => {
        this.$refs.div.scrollTop = this.$refs.div.scrollHeight;
      });
    },
    // 消息已读更新
    $onMessageReadByPeer(v) {
      const data = v.data;
      data.forEach((msg) => {
        this.messageList.forEach((item) => {
          if (msg.ID === item.ID) {
            item.isPeerRead = true;
          }
        });
      });
      this.messageList = this.messageList;
    },
    change(ee) {
      if (ee) {
        return JSON.parse(ee);
      } else {
        return {
          title: "",
          service_type: "",
        };
      }
    },
    Text(Text) {
      return parseText(Text);
    },
  },
};
</script>

<style lang="less" scoped>
.chat-top {
  padding: 15px 30px;
  background-color: #fff;
  .top-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .top-name {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }
}

.tips {
  font-size: 13px;
  text-align: center;
  color: #555;
}
.box {
  position: relative;
  margin-bottom: 20px;

  .Rightpiece-box {
    position: absolute;
    background-color: #33333380;
    color: #ffffff;
    cursor: pointer;
    width: 100px;
    height: 50px;
    top: -50px;
    border-radius: 10px;
    right: 50px;
    text-align: center;
    line-height: 50px;
  }
}

.chat-box {
  overflow: scroll;
  overflow-x: hidden;
  height: 500px;
  padding: 30px;
  background-color: #f4f4f4;
}

.Tool {
  width: 100%;
  background-color: #ffffff;
}

.TIM {
  background-color: #ffffff;
  padding: 10px;
  margin-left: 10px;
  border-radius: 5px 10px 10px 10px;
  max-width: 400px;
  color: #333333;
}

.TIM2 {
  background-color: #fc781d;
  color: #ffffff;
  border-radius: 10px 5px 10px 10px;
  margin-right: 10px;
}

.img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.order-box {
  line-height: 1.5;
  padding: 5px 20px;
}
.custom-pic {
  width: 100px;
  height: 100px;
}
.bold {
  font-weight: 600;
}
.time-text{
  text-align: center;
  height: 20px;
  line-height: 20px;
}
</style>
<template>
    <div>
        <el-dialog title="课程详情" :visible.sync="openorder" width="450px">
            <div class="nr-box">
                <div class="f-zyz nr">
                    <div class="z16-33">课程类目</div>
                    <div class="z14-66">{{ order.categories }}</div>
                </div>
                <div class="f-zyz nr">
                    <div class="z16-33">价格预算(元)</div>
                    <div class="z14-66">{{ order.amount_range }}元</div>
                </div>
                <div class="f-zyz nr">
                    <div class="z16-33">上课方式</div>
                    <div class="z14-66"> {{ service[order.service_type] }}</div>
                </div>
                <div class="f-zyz nr">
                    <div class="z16-33" style="width: 100px;">上课地点</div>
                    <div class="z14-66">{{ order.address || '' }}</div>
                </div>
                <div class="f-zyz nr">
                    <div class="z16-33">学员水平</div>
                    <div class="z14-66">{{ base[order.study_base] }}</div>
                </div>
                <div class="f-zyz nr">
                    <div class="z16-33">备注</div>
                    <div class="z14-66"> {{ order.remark || '' }}</div>
                </div>
                <!-- <div style="text-align: center;margin: 30px 0;">
                    <el-button type="warning" style="margin-right: 20px;" @click="openorder = false">返回</el-button>
                    <el-button type="danger" v-if="order.status != 5" @click="agree">生成需求</el-button>
                </div> -->
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            openorder: false,
            order: {},
            service: {
                1: '海马场地',
                2: '上门教学',
                3: '教练场地'
            },
            base: {
                1: '零基础',
                2: '初入门',
                3: '中级',
                4: '高级'
            },
            need_id: '',

        }
    },
    mounted() {


    },
    methods: {
        agree() {
            if (this.need_id) {
                this.$store.commit('SET_need_id', this.need_id)
                this.openorder = false
               
            } else {
                this.$Ts('错误')
            }
        },
        open(id) {
            this.need_id = id
            this.$get('user/userCourseNeed/findById', { id: id }).then(res => {
                if (res.data.code == 0) {
                    this.order = res.data.data
                    this.openorder = true
                } else {
                    this.$Ts('获取课程失败')
                }
            })
        },

    }
}
</script>

<style lang="less" scoped>
.nr-box {
    .nr {
        font-size: 16px;
        color: #333333;
        min-height: 50px;

        &:not(:last-child) {
            border-bottom: #dddddd 1px solid;
        }


    }
}
</style>
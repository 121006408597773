<template>
  <div class="tool">
    <div class="send-wrap flex jc-c">
      <el-input
        type="textarea"
        placeholder="请输入内容"
        v-model="sendout"
        resize="none"
      >
      </el-input>

      <img
        src="@/assets/app/emoji.png"
        alt=""
        class="e-btn"
        @click="expression = !expression"
      />
      <el-upload
        class="upload-demo"
        action="#"
        :show-file-list="false"
        :limit="1"
        :before-upload="sendImageMessage"
      >
          <img
        src="@/assets/app/pic.png"
        alt=""
        class="e-btn"
      />
      </el-upload>

      <div class="send-btn" @click="Sendamessage">发送</div>
    </div>
    <div v-if="expression" class="emojo-wrap">
      <img
        v-for="(item, index) in emojiName"
        :key="index"
        class="shou"
        @click="TextIng(item)"
        style="width: 30px; height: 30px; margin: 0 5px 5px"
        :src="emojiUrl + emojiMap[item]"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import { emojiName, emojiMap, emojiUrl } from "../base/emojiMap";
export default {
  name: "tool",
  props: {
    user: {
      tpye: Object,
    },
  },
  data() {
    return {
      sendout: "",
      expression: false,
      emojiName: emojiName,
      emojiMap: emojiMap,
      emojiUrl: emojiUrl,
      Demandbox: false,
      coach_id: [],
      NeedList: [],
      total: 0,
      currentPage: 1,
    };
  },
  mounted() {},
  methods: {
    TextIng(item) {
      this.sendout = this.sendout + item;
    },
    Sendamessage() {
      if (!this.sendout) {
        return;
      }
      const message = this.$tim.createTextMessage({
        to: this.user.toAccount,
        conversationType: this.user.type || this.$tims.TYPES.CONV_C2C,
        payload: {
          text: this.sendout,
        },
      });
      this.$sendMessage(message);
    },
    sendImageMessage(file) {
      const message = this.$tim.createImageMessage({
        to: this.user.toAccount,
        conversationType: this.user.type || uni.$TIM.TYPES.CONV_C2C,
        payload: {
          file: file,
        }
      });
      this.$sendMessage(message);
      this.$message('图片发送中')
      return false;
    },
    $sendMessage(message) {
      this.$tim
        .sendMessage(message)
        .then((res) => {
          this.$emit("sendMessage", res.data.message);
          this.sendout = "";
          this.expression = false;
          this.Demandbox = false;
        })
        .catch((error) => {
          this.$Ts("发送失败");
        });
    },
  },
};
</script>
<style lang="less" scoped>
.send-wrap {
  display: flex;
  padding: 20px 30px;
}
.emojo-wrap {
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  right: 0;
  max-height: 300px;
  padding: 30px;
  // overflow-y: scroll;
  bottom: 80px;
  box-sizing: border-box;
}

.tool {
  width: 100%;
  position: relative;
}
.send-btn {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  background-color: #fc7819;
  margin-left: 20px;
  border-radius: 4px;
  cursor: pointer;
}
.e-btn {
  width: 40px;
  height: 40px;
  margin-left: 20px;
}
</style>
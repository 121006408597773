<template>
  <div class="center1">
    <!-- <div v-for="(item, index) in list" :key="index" class="nr-box">
      <div class="flex nr-s">
        <p style="width: 400px;">{{ item.opera_type }}</p>
        <p>{{ item.create_time }}</p>
      </div>
      <div class="nr-x">
        <samp>{{ item.title }}</samp>
      </div>
    </div> -->
    <el-table :data="list" style="width: 100%">
      <el-table-column prop="opera_type">
      </el-table-column>
      <el-table-column prop="title">
      </el-table-column>
      <el-table-column prop="create_time">
      </el-table-column>
    </el-table>
    <div class="fy">
      <paging-fy @currentPageChange="currentPageChange" :currentPage="currentPage" :total="total" :page="10"></paging-fy>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("userInfoEntity")),
      currentPage: 1,
      total: 0,
      list: []
    };
  },
  mounted() {
    this.obtain();
  },
  methods: {
    currentPageChange(v) {
      this.currentPage = v;
      this.obtain();
    },
    obtain() {
      let url = "user/pushHistory/queryManagerListPage";
      let data = {
        target_id: this.user.user_id,
        currentPage: this.currentPage,
        is_teach_paypal: true,
        type: "推送",
        pageSize: 10,
        view_type: "用户"
      };
      this.$get(url, data).then(res => {
        this.list = res.data.data.rows;
        this.total = res.data.data.total;
      });
    }
  }
};
</script>

<style scoped lang="less">
.center1 {
  margin-top: 30px;
  padding: 30px;
  background-color: #ffffff;
  min-height: 620px;
  position: relative;
}

.nr-box {
  margin-bottom: 20px;

  .nr-s {
    color: #666666;
    font-size: 16px;
  }

  .nr-x {
    font-size: 14px;
    color: #666666;
    padding-left: 20px;
  }
}

.fy {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
